jQuery( document ).ready(function( $ ) {

  console.log('Ready javascript');

  // Mobile > all touchstar DOM perfect Iphone
  document.addEventListener("touchstart", function(){}, true);

  // Top Scroll button element
  // https://github.com/bartholomej/material-scrollTop
  $('body').materialScrollTop();

  // anchor link
  $(document).on('click', '[href^="#"]', function (event) {
    event.preventDefault();
    if ($(this).attr('href')!=='#' ){
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
    } else {
      console.log('Desenvolupant');
    }
  });


  // ---------
  $('button.item').on( "click", function(e) {
    e.preventDefault();
    var id = $(this).attr('data-related'); 
    $('.c-controls button').removeClass('is-active');
    $(this).addClass('is-active');
    
    $(".js-eventClick__c-controls").each(function(){
      $(this).removeClass('is-active');
      if($(this).attr('id') == id) {
        $(this).addClass('is-active');
      }
    });
  });



  $('.item__list li').on( "click", function(e) {
    e.preventDefault();
    var data_related_content = $(this).attr('data-related-content'); 

    $(this).closest('article').find('.item__list li').removeClass('is-current');
    $(this).addClass('is-current');

    $(this).closest('article').find('.c-activities__content').removeClass('is-active');
    if ( $('.c-activities__content.'+data_related_content).html() == undefined )
    {
      alert('SIN CONTENIDO');
    }
    $('.c-activities__content.'+data_related_content).addClass('is-active');

    if (window.innerWidth<768) {
      var calcule_scroll_top_div = $(this).closest('article').find('.c-activities__content.'+data_related_content).offset().top;
      $("html, body").animate({ scrollTop: calcule_scroll_top_div  }, 600);
    }
  }); 








  // MENU
  $('.c-menu__nav > ul > li:has( > ul)').addClass('item-menu-dropdown-icon');
  //Checks if li has sub (ul) and adds class for toggle icon - just an UI

  $('.c-menu__nav > ul > li > ul:not(:has(ul))').addClass('item-normal-sub');
  //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

  $(".c-menu__nav > ul").before("<a href=\"#\" class=\"item-menu-mobile\">Menú</a>");
  // Show text UI, version no desktop 

  if ($(window).width() > 1025) {
    var timer;
    var delay = 20;
    $(".c-menu__nav > ul > li").hover(function(e) {
      var $this = $(this);
      timer = setTimeout(function(){
        $this.children("ul").stop(true, false).fadeToggle(150).toggleClass('e-fade-in-menu');
        e.preventDefault();
      }, delay);
    }, function(){
      clearTimeout(timer);
      $(".c-menu__nav > ul > li > ul").css('display','none').removeClass('e-fade-in-menu');
    });
  }
  
  //If width is more than 1024px dropdowns are displayed on hover
  $(".c-menu__nav > ul > li").click(function() {
    if ($(window).width() <= 1025) {
      $(this).children("ul").fadeToggle(150).toggleClass('e-fade-in-menu');
    }
  });
  //If width is var scss/config/_vars.scss var $desktop=1024px or equal to 1025px(1 Pixel extra) dropdowns are displayed on click (thanks)
  $(".item-menu-mobile").click(function(e) {
    $(".c-menu__nav > ul").toggleClass('item-show-on-mobile').toggleClass('e-fade-in-menu');
    e.preventDefault();
  });
  //when clicked on mobile-menu, normal menu is shown as a list



});






